<template>  
  <div class="mt-1 text-right">
    <button type="button" class="ml-3 btn btn-primary" @click="onModify()">{{$t('저장')}}</button>
    <button type="button" v-show="!isRegist" class="ml-2 btn btn-danger" @click="onRemove()">{{$t('삭제')}}</button>
    <button type="button" class="ml-2 btn btn-info" @click="onReset()">{{$t('초기화')}}</button>
  </div>
</template>

<script>

export default {
  components: {},
  props: ["isRegist"],
  watch: {},
  data() {
    return {
    };
  },
  computed: {
    
  },
  created() {
  },
  methods: {
    // 저장 버튼 
    onModify() {
      this.$emit("update");
    },
    // 삭제 버튼
    onRemove(){
      // 코드 등록내역 초기화
      this.$emit("delete");
    },
    // 초기화 버튼
    onReset(){
      this.$emit("reset");
    }
  },
};
</script>
