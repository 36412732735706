<template>  
  <div class="mt-5 text-center">
    <button type="button" class="ml-3 btn btn-light" @click="onSave()">저장</button>
    <button type="button" class="ml-3 btn btn-light" @click="onReset()">초기화</button>
  </div>
</template>

<script>

export default {
  components: {},
  props: [],
  watch: {},
  data() {
    return {
    };
  },
  computed: {
    
  },
  created() {
  },
  methods: {
    // 저장 버튼 
    onSave() {
      this.$emit("insert");
    },
    onReset(){
      this.$emit("reset");
    }
  },
};
</script>
