<template>
	<div>
		<div class="row p-l-15 p-b-10">
			<div class="font-weight-bold d-flex flex-row" :style="{ fontSize: '15px' }">
				<div class="pt-2 p-l-15">
					<span>{{$t('계통')}}</span>
				</div>
				<div class="p-l-5">
					<!-- <select class="form-control" ref="enabled" v-model="searchInfo.systemCode"> </select> -->
					<b-form-select ref="systemCodeSearch" v-model="searchInfo.systemCode" :options="systemCodeList"></b-form-select>
				</div>
			</div>
			<div class="font-weight-bold d-flex flex-row" :style="{ fontSize: '15px' }">
				<div class="pt-2 p-l-15">
					<span>{{$t('설비 용도')}}</span>
				</div>
				<div class="p-l-5">
					<!-- <select class="form-control" ref="enabled" v-model="searchInfo.itemUsage"> </select> -->
					<b-form-select ref="itemUsageSearch" v-model="searchInfo.itemUsage" :options="itemUsageList"></b-form-select>
				</div>
			</div>
			<div class="font-weight-bold d-flex flex-row" :style="{ fontSize: '15px' }">
				<div class="pt-2 p-l-15">
					<span>{{$t('설비 유형')}}</span>
				</div>
				<div class="p-l-5">
					<!-- <select class="form-control" ref="enabled" v-model="searchInfo.equipType"> </select> -->
					<b-form-select ref="equipTypeSearch" v-model="searchInfo.equipTypeCode" :options="equipTypeList"></b-form-select>
				</div>
			</div>

			<div class="font-weight-bold d-flex flex-row" :style="{ fontSize: '15px' }">
				<div class="pt-2 p-l-5">
					<span>{{$t('설비명')}}</span>
				</div>
				<div class="p-l-5">
					<input :ref="'keyword'" class="form-control" v-model="searchInfo.equipName" @keydown.enter="onSearch" />
				</div>
			</div>
			<div class="font-weight-bold d-flex flex-row" :style="{ fontSize: '15px' }">
				<div class="pt-2 p-l-15">
					<span>{{$t('사용여부')}}</span>
				</div>
				<div class="p-l-5">
					<select class="form-control" ref="enabled" v-model="searchInfo.enabled">
						<option value="">{{$t('전체')}}</option>
						<option value="Y">{{$t('사용')}}</option>
						<option value="N">{{$t('사용안함')}}</option>
					</select>
				</div>
			</div>
			<div class="font-weight-bold d-flex flex-row p-l-5" :style="{ fontSize: '15px' }">
				<button class="form-control" @click="onSearch()">{{$t('조회')}}</button>
				<button class="form-control ml-1 mr-1" @click="onNew">{{$t('신규')}}</button>
				<!-- <button class="form-control" @click="onRemove">삭제</button> -->
			</div>
		</div>
	</div>
</template>

<script>
import backEndApi from "../../../api/backEndApi";
import AppErrorHandler from "@src/appErrorHandler";

export default {
	props: [],
	components: {},
	watch: {},
	data() {
		return {
			EquipDeviceData: null,
			searchInfo: {
				systemCode: "",
				itemUsage: "",
				equipTypeCode: "",
				equipName: "",
				enabled: "",
			},
			systemCodeList: null,
			itemUsageList: null,
			equipTypeList: null,
		};
	},
	created() {
		// 처음 진입 시에만 호출
		this.init();
	},
	mounted() { },
	methods: {
		async init() {
			this.onSearch();
			// this.getEquipDeviceList(this.searchInfo);
			await this.getConditions();
		},
		async getEquipDeviceList(valuation) {
			let result = await backEndApi.equipInfo.getEquipInfoList(valuation);
			return result.data;
			// backEndApi.equipInfo.getEquipInfoList(valuation).then(({ data }) => {
			//   if (!data.errno) {
			//     this.EquipDeviceData = data;
			//     // this.$emit("search:data", this.EquipDeviceData);
			//   } else {
			//     this.alertNoti(data.message);
			//   }
			// });
		},
		async getConditions() {

			// 이전 리비전과 소스 에서 아래와 같이 변경함.
			/*           
				↓↓↓↓↓
			*/
			try {

				let result = await backEndApi.systeminfo.getSystemInfoList();
				let data = result.data;

				let systemCodeOptions = [{ value: "", text: global.xe.$t("전체") }];

				for (let i = 0; i < data.length; i++) {
					systemCodeOptions.push({
						value: data[i].systemCode,
						text: data[i].systemName,
					});
				}
				this.systemCodeList = systemCodeOptions;

				result = await backEndApi.publicCode.getOprtnSmallCodeList("itemUseageCode");
				data = result.data;

				let itemUsageOptions = [{ value: "", text: global.xe.$t("전체") }];
				for (let i = 0; i < data.length; i++) {
					itemUsageOptions.push({
						value: data[i].smallCode,
						text: data[i].smallCodeName,
					});
				}
				this.itemUsageList = itemUsageOptions;

				result = await backEndApi.equipType.getEquipTypeList();
				data = result.data;

				let equipTypeOptions = [{ value: "", text: global.xe.$t("전체") }];
				for (let i = 0; i < data.length; i++) {
					equipTypeOptions.push({
						value: data[i].equipTypeCode,
						text: data[i].equipTypeCodeName,
					});
				}
				this.equipTypeList = equipTypeOptions;

			} catch (err) {
				new AppErrorHandler(err)
					.printErrorLog()
					.errHandling();
			}
		},

		// Event
		onChangeCombo() {
			this.onSearch();
		},

		async onSearch() {
			// let systemCode = this.searchInfo.systemCode; // 계통
			// let itemUsage = this.searchInfo.itemUsage; // 설비 용도
			// let equipType = this.searchInfo.equipType; // 설비 유형
			// let equipName = this.searchInfo.equipName; // 설비명
			// let enabled = this.searchInfo.enabled; // 사용여부

			var result = await this.getEquipDeviceList(this.searchInfo);
			this.EquipDeviceData = result;
			this.$emit("search:data", result);
		},
		async reSearch() {
			this.EquipDeviceData = await this.getEquipDeviceList(this.searchInfo);

			this.$emit("search:data", this.EquipDeviceData);
		},
		onNew() {
			this.$emit("new");
		},
	},
};
</script>

<style scoped></style>
