<template>
  <b-table
    hover
    bordered
    head-variant="dark"
    no-border-collapse
    show-empty
    sticky-header
    ref="selectableTable"
    :class="'text-center'"
    :items="items"
    :fields="fields"
    :empty-text="$t('조회된 결과가 없습니다.')"
    :select-mode="'single'"
    selectable
    :details-td-class="'rowDetailStyle'"
    @row-selected="onRowSelected"
    @row-clicked="onRowDetail"
  >
    <template #empty="scope">
      <h4>{{ scope.emptyText }}</h4>
    </template>

    <template #cell(selected)="{ rowSelected }">
      <template v-if="rowSelected">
        <span>&check;</span>
        <span class="sr-only">Selected</span>
      </template>
      <template v-else>
        <span>&nbsp;</span>
        <span class="sr-only">Not selected</span>
      </template>
    </template>

    <template #cell(index)="data">
      {{ data.index + 1 }}
    </template>

    <template #row-details="row" :class="'bg-white'">
      <b-card>
        <div>
          <detail-btn @update="onModify" @delete="onRemove" @reset="onReset" />
        </div>

        <detail-box :ref="'detail'" :equipIdx="row.item.equipIdx" :upperEquipIdx="row.item.upperEquipIdx" :groupIdx="row.item.equipGrpIdx" @reSearch="reSearch" />
      </b-card>
    </template>
  </b-table>
</template>

<style scoped>
.b-table-sticky-header {
  max-height: 760px;
}
</style>

<script>
// import detailCard from "./GridCard.vue";
// import detailBox from "../../../../system-type/equip-type/EquipTypeDetail.vue";
// import detailBtn from "../../../../system-type/equip-type/EquipTypeDetailBtn.vue";

import detailBox from "./EquipDeviceDetail.vue";
import detailBtn from "./EquipDeviceDetailBtn.vue";

export default {
  components: {
    detailBox,
    detailBtn,
    // detailCard,,detailBtn
  },
  props: ["data"],
  data() {
    return {
      fields: null,
      items: null,
      emptyText: null,
      selectedRow: [],
    };
  },
  watch: {
    data() {
      this.selectedRow._showDetails = !this.selectedRow._showDetails;
      this.setItems();
    },
  },
  created() {
    this.setFields();
    this.setItems();
  },
  mounted() {},
  methods: {
    onRowSelected(item) {
      if (!item.length > 0) {
        this.selectedRow._showDetails = false;
        this.selectedRow = [];
      }
    },
    onRowDetail(row) {
      // 이전 선택된 row close
      this.selectedRow._showDetails = !this.selectedRow._showDetails;

      // 선택된 row open
      row._showDetails = !row._showDetails;

      // 선택된 row 저장
      this.selectedRow = row;
    },
    clearSeclected() {
      this.$refs.selectableTable.clearSelected();
    },
    setFields() {
      this.fields = [
        { key: "index", label: global.xe.$t("순번") },
        { key: "equipName", label: global.xe.$t("설비명"), tdClass: "text-left" },
        { key: "systemCodeName", label: global.xe.$t("계통"), tdClass: "text-left" },
        { key: "equipTypeCodeName", label: global.xe.$t("설비 유형"), tdClass: "text-left" },
        { key: "equipUsageCodeName", label: global.xe.$t("설비 용도"), tdClass: "text-left" },
        { key: "enabled", label: global.xe.$t("사용여부") },
      ];
    },
    setItems() {
      this.items = this.data;
    },
    onModify() {
      this.$refs.detail.onModify();
    },
    onRemove() {
      this.$refs.detail.onRemove();
    },
    onReset() {
      this.$refs.detail.onReset();
    },
    reSearch() {
      this.$emit("reSearch");
    },
  },
};
</script>
