<template>
	<div>
		<!-- <div class="row-vh d-flex flex-row"> -->
		<div class="row-vh d-flex flex-row">
			<div class="w-50">
				<div class="d-flex flex-row align-items-center mt-3">
					<div class="col-4 text-left d-flex"><span class="h5 mb-0">{{$t('설비 유형')}}</span><i class="material-icons ml-1 m-t-1"
							:style="{ fontSize: '15px' }">check_circle</i></div>
					<div class="col-8">
						<b-form-select :ref="'equipTypeCode'" v-model="model.equipTypeCode" :options="detailEquipTypeList"
							@input="upperTypeCheck"> </b-form-select>

						<!-- <input :ref="'equipTypeCode'" class="form-control" v-model="model.equipTypeCode" disabled="disabled" /> -->
					</div>
				</div>
				<div class="d-flex flex-row align-items-center mt-3">
					<div class="col-4 text-left d-flex"><span class="h5 mb-0">{{$t('설비 계통')}}</span><i class="material-icons ml-1 m-t-1"
							:style="{ fontSize: '15px' }">check_circle</i></div>
					<div class="col-8">
						<!-- <input :ref="'equipTypeCodeName'" class="form-control" v-model="model.equipTypeCodeName" /> -->
						<b-form-select :ref="'systemCode'" v-model="model.systemCode" :options="detailSystemInfoList">
						</b-form-select>
					</div>
				</div>
			</div>
			<div class="w-50">
				<div class="d-flex flex-row align-items-center mt-3">
					<div class="col-4 text-left d-flex"><span class="h5 mb-0">{{$t('설비명')}}</span><i class="material-icons ml-1 m-t-1"
							:style="{ fontSize: '15px' }">check_circle</i></div>
					<div class="col-8">
						<valid-input :inputType="'text'" :inputValue.sync="model.equipName" :rules="rules.EQUIP_ADD_NAME_RULE"
							:errorMessage="validMessage.COMMON_BOTH_LENGTH_VALID_MESSAGE(1, 50)" :isMessage="true"></valid-input>
					</div>
				</div>

				<!-- <div class="d-flex flex-row align-items-center mt-3">
        <div class="col-3 text-left d-flex">
          <span class="h5 mb-0">설비 도면</span>
        </div>
        <div class="col-8">
          <input :ref="'dsnFilePath'" class="form-control" v-model="model.dsnFilePath" />
        </div>
      </div> -->
				<div class="d-flex flex-row align-items-center mt-3">
					<div class="col-4 text-left d-flex"><span class="h5 mb-0">{{$t('설비 용도')}}</span><i class="material-icons ml-1 m-t-1"
							:style="{ fontSize: '15px' }">check_circle</i></div>
					<div class="col-8">
						<b-form-select :ref="'equipUsageCode'" v-model="model.equipUsageCode" :options="detailItemUseageList">
						</b-form-select>
					</div>
				</div>
			</div>
		</div>
		<div v-if="isUpperTypeCode !== ''">
			<div class="row-vh d-flex flex-row">
				<div class="w-50">
					<div class="d-flex flex-row align-items-center mt-3">
						<div class="col-4 text-left d-flex"><span class="h5 mb-0">{{$t('상위 설비 기기')}}</span></div>
						<div class="col-8">
							<b-form-select :ref="'upperTypeCode'" v-model="model.upperEquipIdx" :options="upperTypeCodeList"
								@input="upperDataInput"> </b-form-select>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-else>
			<div class="row-vh d-flex flex-row">
				<div class="w-50">
					<div class="d-flex flex-row align-items-center mt-3">
						<div class="col-4 text-left d-flex"><span class="h5 mb-0">{{$t('설비 그룹')}}</span></div>
						<div class="col-8">
							<b-row>
								<b-col cols="10">
									<b-form-select :ref="'upperTypeCode'" v-model="model.equipGrpIdx" :options="groupTypeList"
										@input="groupDataInput"> </b-form-select>
								</b-col>
								<b-col cols="2" :style="{ padding: 0, textAlign: 'left' }">
									<b-button v-b-toggle.collapse-1>{{$t('신규')}}</b-button>
								</b-col>
							</b-row>
						</div>
					</div>
					<div class="d-flex flex-row align-items-center" :style="{ justifyContent: 'right' }">
						<b-collapse id="collapse-1" class="mt-2 col-8">
							<b-card>
								<div class="d-flex flex-row align-items-center">
									<div class="col-4 text-left d-flex"><span class="h6 mb-0" :style="{ fontSize: '13px' }">{{$t('설비 그룹명')}}</span>
									</div>
									<div class="col-8">
										<valid-input :inputType="'text'" :inputValue.sync="newGroup.equipGrpName"
											:rules="rules.EQUIP_GROUP_NAME_RULE"
											:errorMessage="validMessage.COMMON_BOTH_LENGTH_VALID_MESSAGE(1, 30)"
											:isMessage="true"></valid-input>

										<!-- <input :ref="'equipGroupName'" class="form-control" v-model="newGroup.equipGrpName" /> -->
									</div>
								</div>
								<div class="d-flex flex-row align-items-center mt-3">
									<div class="col-4 text-left d-flex"><span class="h6 mb-0" :style="{ fontSize: '13px' }">{{$t('구역')}}</span></div>
									<div class="col-8">
										<tree-select :load-options="loadOptions" v-model="newGroup.zoneIdx"
											:placeholder="controlMessages.ZONE_TREE_PLACEHOLDER_MESSAGE"
											:noChildrenText="controlMessages.ZONE_TREE_NOCHILDREN_MESSAGE"
											:noResultsText="$t(controlMessages.COMMON_TREE_NOSEARCH_MESSAGE)" :multiple="false"
											:options="detailZoneList" />
									</div>
								</div>
								<div class="d-flex flex-row align-items-center mt-3" :style="{ justifyContent: 'center' }">
									<b-button @click="makeNewGroup">{{$t('저장')}}</b-button>
								</div>
							</b-card>
						</b-collapse>
					</div>
				</div>
			</div>
		</div>
		<div>
			<div class="row-vh d-flex flex-row">
				<div class="w-50">
					<div class="d-flex flex-row align-items-center mt-3">
						<div class="col-4 text-left d-flex"><span class="h5 mb-0">{{$t('구역')}}</span></div>
						<div class="col-8">
							<tree-select :load-options="loadOptions" v-model="model.zoneIdx"
								:placeholder="controlMessages.ZONE_TREE_PLACEHOLDER_MESSAGE"
								:noChildrenText="controlMessages.ZONE_TREE_NOCHILDREN_MESSAGE"
								:noResultsText="$t(controlMessages.COMMON_TREE_NOSEARCH_MESSAGE)" :multiple="false"
								:options="detailZoneList" />
						</div>
					</div>
					<div class="d-flex flex-row align-items-center mt-3">
						<div class="col-4 text-left d-flex"><span class="h5 mb-0">X {{$t('좌표')}}</span></div>
						<div class="col-8">
							<valid-input :inputType="'number'" :inputValue.sync="upperTypeData.equipLctn.split(',')[0]"
								:rules="rules.EQUIP_POSITION_X_RULE" :NonStatus="true" :changeMethod="coorChangeX"></valid-input>
						</div>
					</div>
				</div>
				<div class="w-50" :style="{ alignSelf: 'flex-end' }">
					<div class="d-flex flex-row align-items-center mt-3">
						<!-- <div class="col-4 text-left d-flex"><span class="h5 mb-0">사용 에너지원</span></div>
            <div class="col-8">
              <b-form-select :ref="'upperTypeCode'" v-model="isSelectedEnergy" :options="detailUpperEnergyList"> </b-form-select>
            </div> -->
					</div>
					<div class="d-flex flex-row align-items-center mt-3">
						<div class="col-4 text-left d-flex"><span class="h5 mb-0">Y {{$t('좌표')}}</span></div>
						<div class="col-8">
							<valid-input :inputType="'number'" :inputValue.sync="upperTypeData.equipLctn.split(',')[1]"
								:rules="rules.EQUIP_POSITION_Y_RULE" :NonStatus="true" :changeMethod="coorChangeY"></valid-input>
						</div>
					</div>
				</div>
			</div>
			<div class="row-vh d-flex flex-row">
				<div class="w-100">
					<div class="d-flex flex-row align-items-center mt-3">
						<div class="col-2 text-left d-flex"><span class="h5 mb-0">{{$t('기타 항목')}}</span></div>
						<div class="col-10">
							<valid-textarea :inputType="'text'" :inputValue.sync="upperTypeData.equipProp" :NonStatus="true"
								:rules="rules.EQUIP_ETCINFO_RULE"></valid-textarea>
						</div>
					</div>
				</div>
			</div>
			<div class="row-vh d-flex flex-row">
				<div class="w-50">
					<div class="d-flex flex-row align-items-center mt-3">
						<div class="col-4 text-left d-flex"><span class="h5 mb-0">{{$t('사용여부')}}</span></div>
						<div class="col-8">
							<select class="form-control" ref="enabled" v-model="model.enabled">
								<option value="Y">{{$t('사용')}}</option>
								<option value="N">{{$t('사용안함')}}</option>
							</select>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- </div> -->
	</div>
</template>

<style scoped>
.form-control-none {
	display: block;
	width: 100%;
	height: calc(1.5em + 14px + 2px);
	padding: 7px 0.75rem;
	font-size: 0.75rem;
	font-weight: 400;
	/* line-height: 1.5; */
	color: #2d353c;
	background-color: #fff;
	background-clip: padding-box;
	border: 0px solid #d5dbe0;
	border-radius: 4px;
	-webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
	transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}
</style>

<script>
import backEndApi from "../../../api/backEndApi";
import config from "@libs/config.js";
import AppErrorHandler from "@src/appErrorHandler";
import * as rules from "@src/consts/ruleConsts.js";
import * as validMessage from "@src/consts/validMessageConsts.js";
import * as popupMessages from "@src/consts/popupMessageConsts";
import * as controlMessages from "@src/consts/controlMessageConsts";

export default {
	components: {},
	props: ["equipIdx", "upperEquipIdx", "groupIdx"],
	watch: {},
	data() {
		return {
			model: {
				equipIdx: null,
				equipTypeCode: null,
				systemCode: null,
				equipName: null,
				equipUsageCode: null,
				equipProp: null,
				zoneIdx: null,
				equipLctn: "",
				enabled: null,
				equipGrpIdx: null,
				upperEquipIdx: null,
			},
			originModel: {
				equipIdx: null,
				equipTypeCode: null,
				systemCode: null,
				equipName: null,
				equipUsageCode: null,
				equipProp: null,
				zoneIdx: null,
				equipLctn: "",
				enabled: null,
				equipGrpIdx: null,
				upperEquipIdx: null,
			},
			upperTypeData: {
				equipIdx: "",
				equipTypeCode: "",
				systemCode: "",
				equipName: "",
				equipUsageCode: "",
				equipProp: "",
				zoneIdx: "",
				equipLctn: "",
				enabled: "",
			},
			groupTypeData: {
				equipGrpIdx: "",
				equipTypeCode: "",
				equipGrpName: "",
				enabled: "",
			},
			newGroup: {
				equipTypeCode: "",
				equipGrpName: "",
				systemCode: "",
				enabled: "Y",
				zoneIdx: 1,
			},
			isSelectedEnergy: "",
			upperTypeCodeList: null,
			groupTypeList: [],

			isUpperTypeCode: null,
			detailEquipTypeList: null,
			detailSystemInfoList: null,
			detailItemUseageList: null,
			// detailUpperEnergyList: null,
			detailZoneList: null,

			xemsHost: config.backend.apiUrl,
			rules,
			validMessage,
			popupMessages,
			controlMessages
		};
	},
	computed: {},
	async created() {
		this.detailEquipTypeList = await this.getEquipTypeList();
		this.detailSystemInfoList = await this.getSystemInfoList();
		this.detailItemUseageList = await this.getOprtnSmallCodeList("itemUseageCode");
		// this.detailUpperEnergyList = await this.getOprtnSmallCodeList("energySourceCode");
		this.detailZoneList = await this.getZoneListHierarchi();
		await this.setModel();

		this.model.upperEquipIdx = this.upperEquipIdx;
		this.model.equipGrpIdx = this.groupIdx;
		this.originModel.upperTypeData = this.upperEquipIdx;
		this.originModel.equipGrpIdx = this.groupIdx;
	},
	methods: {
		async makeNewGroup() {
			if (this.model.equipTypeCode.trim() == "") {
				this.alertNoti(popupMessages.EQUIP_INSERT_DEVICE_TYPE_VALID_POPUP_MESSAGE);
				return;
			} else if (this.model.systemCode.trim() == "") {
				this.alertNoti(popupMessages.EQUIP_DEVICE_SYSTEM_CODE_INPUT_VALID_POPUP_MESSAGE);
				return;
			} else if (this.newGroup.equipGrpName.trim() == "") {
				this.alertNoti(popupMessages.EQUIP_DEVICE_GRUOP_VALID_POPUP_MESSAGE);
				return;
			}

			this.newGroup.equipTypeCode = this.model.equipTypeCode;
			this.newGroup.systemCode = this.model.systemCode;

			// 이전 리비전과 소스 에서 아래와 같이 변경함.
			/*           
				↓↓↓↓↓
			*/
			try {
				// 세이브 시작
				await backEndApi.equipInfo.insertEquipGroup(this.newGroup);
				this.alertNoti(popupMessages.COMMON_SAVE_POPUP_MESSAGE);
			} catch (err) {
				new AppErrorHandler(err)
					.setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger)
					.errHandling();
			}

		},
		coorChangeX(e) {
			this.model.equipLctn = e.target.value + "," + this.model.equipLctn.split(",")[1];
		},
		coorChangeY(e) {
			this.model.equipLctn = this.model.equipLctn.split(",")[0] + "," + e.target.value;
		},
		async getSystemInfoList() {

			// 이전 리비전과 소스 에서 아래와 같이 변경함.
			/*           
				↓↓↓↓↓
			*/
			try {
				let result = await backEndApi.systeminfo.getSystemInfoList();
				let options = [];
				for (let i = 0; i < result.data.length; i++) {
					options.push({
						value: result.data[i].systemCode,
						text: result.data[i].systemName,
					});
				}
				return options;
			} catch (err) {
				new AppErrorHandler(err)
					.setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger)
					.errHandling();
			}

		},
		async getOprtnSmallCodeList(code) {

			// 이전 리비전과 소스 에서 아래와 같이 변경함.
			/*           
				↓↓↓↓↓
			*/
			try {
				let result = await backEndApi.publicCode.getOprtnSmallCodeList(code);
				let options = [];
				for (let i = 0; i < result.data.length; i++) {
					options.push({
						value: result.data[i].smallCode,
						text: result.data[i].smallCodeName,
					});
				}
				return options;
			} catch (err) {
				new AppErrorHandler(err)
					.setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger)
					.errHandling();
			}
		},
		async getEquipTypeList() {

			// 이전 리비전과 소스 에서 아래와 같이 변경함.
			/*           
				↓↓↓↓↓
			*/
			try {
				let result = await backEndApi.equipType.getEquipTypeList();
				let options = [];
				for (let i = 0; i < result.data.length; i++) {
					options.push({
						value: result.data[i].equipTypeCode,
						text: result.data[i].equipTypeCodeName,
						upperTypeCode: result.data[i].upperTypeCode,
					});
				}

				return options;

			} catch (err) {
				new AppErrorHandler(err)
					.setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger)
					.errHandling();
			}
		},
		async setModel() {

			// 이전 리비전과 소스 에서 아래와 같이 변경함.
			/*           
				↓↓↓↓↓
			*/
			try {
				let result = await backEndApi.equipInfo.getEquipDetailInfo(this.equipIdx);
				let data = result.data;

				this.model = {
					equipIdx: data.equipIdx,
					equipTypeCode: data.equipTypeCode,
					systemCode: data.systemCode,
					equipName: data.equipName,
					equipUsageCode: data.equipUsageCode,
					equipProp: data.equipProp,
					zoneIdx: data.zoneIdx,
					equipLctn: data.equipLctn === "" ? "," : data.equipLctn,
					enabled: data.enabled,
					equipGrpIdx: data.equipGrpIdx,
					upperEquipIdx: data.upperEquipIdx,
				};
				this.originModel = {
					equipIdx: data.equipIdx,
					equipTypeCode: data.equipTypeCode,
					systemCode: data.systemCode,
					equipName: data.equipName,
					equipUsageCode: data.equipUsageCode,
					equipProp: data.equipProp,
					zoneIdx: data.zoneIdx,
					equipLctn: data.equipLctn === "" ? "," : data.equipLctn,
					enabled: data.enabled,
					equipGrpIdx: data.equipGrpIdx,
					upperEquipIdx: data.upperEquipIdx,
				};

				return "success";
			} catch (err) {
				new AppErrorHandler(err)
					.setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger)
					.errHandling();
				return "error";
			}

			// backEndApi.equipInfo.getEquipDetailInfo(this.equipIdx).then(({ data }) => {
			//   if (!data.errno) {
			//     this.model = {
			//       equipIdx: data.equipIdx,
			//       equipTypeCode: data.equipTypeCode,
			//       systemCode: data.systemCode,
			//       equipName: data.equipName,
			//       equipUsageCode: data.equipUsageCode,
			//       equipProp: data.equipProp,
			//       zoneIdx: data.zoneIdx,
			//       equipLctn: data.equipLctn === "" ? "," : data.equipLctn,
			//       enabled: data.enabled,
			//       equipGrpIdx: data.equipGrpIdx,
			//       upperEquipIdx: data.upperEquipIdx,
			//     };
			//     this.originModel = {
			//       equipIdx: data.equipIdx,
			//       equipTypeCode: data.equipTypeCode,
			//       systemCode: data.systemCode,
			//       equipName: data.equipName,
			//       equipUsageCode: data.equipUsageCode,
			//       equipProp: data.equipProp,
			//       zoneIdx: data.zoneIdx,
			//       equipLctn: data.equipLctn === "" ? "," : data.equipLctn,
			//       enabled: data.enabled,
			//       equipGrpIdx: data.equipGrpIdx,
			//       upperEquipIdx: data.upperEquipIdx,
			//     };
			//     console.log(data.upperEquipIdx);
			//     this.upperTypeData.equipIdx = data.upperEquipIdx;
			//     this.groupTypeData.equipGrpIdx = data.equipGrpIdx;
			//   } else {
			//     this.alertNoti(data.message);
			//   }
			// });
		},
		async setUpperTypeData(idx) {

			// 이전 리비전과 소스 에서 아래와 같이 변경함.
			/*           
				↓↓↓↓↓
			*/
			try {
				let result = await backEndApi.equipInfo.getEquipDetailInfo(idx);

				let data = {
					equipIdx: result.data.equipIdx,
					equipTypeCode: result.data.equipTypeCode,
					systemCode: result.data.systemCode,
					equipName: result.data.equipName,
					equipUsageCode: result.data.equipUsageCode,
					equipProp: result.data.equipProp,
					zoneIdx: result.data.zoneIdx,
					equipLctn: result.data.equipLctn === "" ? "," : result.data.equipLctn,
					enabled: result.data.enabled,
				};
				return data;

			} catch (err) {
				new AppErrorHandler(err)
					.setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger)
					.errHandling();
			}

		},
		async upperTypeCheck(value) {
			this.isUpperTypeCode = this.detailEquipTypeList.find((el) => el.value === value).upperTypeCode;

			if (this.isUpperTypeCode !== "") {
				this.upperTypeCodeList = await this.getUpperEquipList();
			} else {
				this.groupTypeList = await this.getEquipGroupList(this.model.equipTypeCode);
			}
		},
		async getUpperEquipList() {

			// 이전 리비전과 소스 에서 아래와 같이 변경함.
			/*           
				↓↓↓↓↓
			*/
			try {
				let result = await backEndApi.equipInfo.getUpperEquipList(this.isUpperTypeCode);
				let options = [];
				for (let i = 0; i < result.data.length; i++) {
					options.push({
						value: result.data[i].equipIdx,
						text: result.data[i].equipName,
					});
				}

				return options;
			} catch (err) {
				new AppErrorHandler(err)
					.setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger)
					.errHandling();
			}
		},
		async upperDataInput(idx) {
			if (idx !== undefined) {
				this.upperTypeData = await this.setUpperTypeData(idx);
			}
		},

		async groupDataInput(idx) {
			let result = this.groupTypeList.find((el) => el.value === idx);
			if (result !== undefined) {
				let form = {
					equipGrpIdx: result.value,
					equipTypeCode: result.equipTypeCode,
					equipGrpName: result.text,
					enabled: result.enabled,
				};
				this.groupTypeData = form;
			}
		},

		async getZoneListHierarchi() {

			// 이전 리비전과 소스 에서 아래와 같이 변경함.
			/*           
				↓↓↓↓↓
			*/
			try {
				let result = await backEndApi.zoneInfo.getZoneListHierarchi();
				let options = [];
				let temp = [];
				temp.push(result.data);
				options = this.setZoneTreeOption(temp);
				return options;
			} catch (err) {
				new AppErrorHandler(err)
					.setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger)
					.errHandling();
			}
		},
		setZoneTreeOption(resultData) {
			resultData.forEach((data) => {
				data.id = data.zoneIdx;
				data.label = data.zoneName;

				if (data.children.length === 0) {
					delete data.children;
				}

				if (data["children"]) {
					return this.setZoneTreeOption(data["children"]);
				} else {
					return data;
				}
			});

			return resultData;
		},
		async getEquipGroupList(equipTypeCode) {

			// 이전 리비전과 소스 에서 아래와 같이 변경함.
			/*           
				↓↓↓↓↓
			*/
			try {
				let result = await backEndApi.equipInfo.getEquipGroupList(equipTypeCode);
				let options = [];

				for (let i = 0; i < result.data.length; i++) {
					options.push({
						value: result.data[i].equipGrpIdx,
						text: result.data[i].equipGrpName,
						equipTypeCode: result.data[i].equipTypeCode,
						enabled: result.data[i].enabled,
					});
				}

				return options;

			} catch (err) {
				new AppErrorHandler(err)
					.printErrorLog()
					.errHandling();
				return [];
			}
		},
		async onModify() {

			// 이전 리비전과 소스 에서 아래와 같이 변경함.
			/*           
				↓↓↓↓↓
			*/
			if (this.isValidation()) {
				try {
					let param = this.model;

					await backEndApi.equipInfo.updateEquipInfo(param);
					this.alertNoti(popupMessages.COMMON_UPDATE_POPUP_MESSAGE);
					this.$emit("reSearch");

				} catch (err) {
					new AppErrorHandler(err)
						.setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger)
						.errHandling();
				}
			}
		},
		isValidation() {
			var flag = true;
			if (this.model.equipTypeCode.trim() === "") {
				this.$refs.equipTypeCode.focus();
				this.alertNoti(popupMessages.EQUIP_DEVICE_TYPE_VALID_POPUP_MESSAGE);
				flag = false;
			} else if (this.model.equipName.trim() === "") {
				this.$refs.equipName.focus();
				this.alertNoti(popupMessages.EQUIP_DEVICE_NAME_POPUP_MESSAGE);
				flag = false;
			} else if (this.model.systemCode.trim() === "") {
				this.$refs.systemCode.focus();
				this.alertNoti(popupMessages.EQUIP_DEVICE_SYSTEM_CODE_VALID_POPUP_MESSAGE);
				flag = false;
			} else if (this.model.equipUsageCode.trim() === "") {
				this.$refs.equipUsageCode.focus();
				this.alertNoti(popupMessages.EQUIP_DEVICE_USAGE_CODE_VALID_POPUP_MESSAGE);
				flag = false;
			} else {
				return flag;
			}
		},
		async onRemove() {
			console.log("Grid detail : onRemove");

			// let param = {
			//   equipTypeCode : this.$refs.equipTypeCode.value,
			// }				

			// 이전 리비전과 소스 에서 아래와 같이 변경함.
			/*           
				↓↓↓↓↓
			*/
			const result = await this.alertConfirmWarning(popupMessages.COMMON_DELETE_CONFIRM_POPUP_MESSAGE);
			if (!result.value)
				return;


			try {
				await backEndApi.equipInfo.deleteEquipInfo(this.model.equipIdx);
				await this.alertNoti(popupMessages.COMMON_DELETE_POPUP_MESSAGE);
				this.$emit("reSearch");
			} catch (err) {
				new AppErrorHandler(err)
					.setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger)
					.errHandling();
			}
		},
		onReset() {
			console.log(this.originModel);
			this.model = JSON.parse(JSON.stringify(this.originModel));
		},
		loadOptions({ callback }) {
			callback();
		},
	},
};
</script>
