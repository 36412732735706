<template>
	<div class="EquipDeviceNewModal">
		<!-- <div class="row-vh d-flex flex-row"> -->
		<div class="row-vh d-flex flex-row">
			<div class="w-50">
				<div class="d-flex flex-row align-items-center mt-3">
					<div class="col-4 text-left d-flex"><span class="h5 mb-0">{{$t('설비 유형')}}</span><i class="material-icons ml-1 m-t-1" :style="{ fontSize: '15px' }">check_circle</i></div>
					<div class="col-8">
						<b-form-select :ref="'equipTypeCode'" v-model="model.equipTypeCode" :options="detailEquipTypeList" @input="upperTypeCheck"> </b-form-select>

						<!-- <input :ref="'equipTypeCode'" class="form-control" v-model="model.equipTypeCode" disabled="disabled" /> -->
					</div>
				</div>
				<div class="d-flex flex-row align-items-center mt-3">
					<div class="col-4 text-left d-flex"><span class="h5 mb-0">{{$t('설비 계통')}}</span><i class="material-icons ml-1 m-t-1" :style="{ fontSize: '15px' }">check_circle</i></div>
					<div class="col-8">
						<!-- <input :ref="'equipTypeCodeName'" class="form-control" v-model="model.equipTypeCodeName" /> -->
						<b-form-select :ref="'systemCode'" v-model="model.systemCode" :options="detailSystemInfoList"> </b-form-select>
					</div>
				</div>
			</div>
			<div class="w-50">
				<div class="d-flex flex-row align-items-center mt-3">
					<div class="col-4 text-left d-flex"><span class="h5 mb-0">{{$t('설비명')}}</span><i class="material-icons ml-1 m-t-1" :style="{ fontSize: '15px' }">check_circle</i></div>
					<div class="col-8">
						<input :ref="'equipName'" class="form-control" v-model="model.equipName" />
					</div>
				</div>

				<!-- <div class="d-flex flex-row align-items-center mt-3">
        <div class="col-3 text-left d-flex">
          <span class="h5 mb-0">설비 도면</span>
        </div>
        <div class="col-8">
          <input :ref="'dsnFilePath'" class="form-control" v-model="model.dsnFilePath" />
        </div>
      </div> -->
				<div class="d-flex flex-row align-items-center mt-3">
					<div class="col-4 text-left d-flex"><span class="h5 mb-0">{{$t('설비 용도')}}</span><i class="material-icons ml-1 m-t-1" :style="{ fontSize: '15px' }">check_circle</i></div>
					<div class="col-8">
						<b-form-select :ref="'equipUsageCode'" v-model="model.equipUsageCode" :options="detailItemUseageList"> </b-form-select>
					</div>
				</div>
			</div>
		</div>
		<div v-if="isUpperTypeCode !== ''">
			<div class="row-vh d-flex flex-row">
				<div class="w-50">
					<div class="d-flex flex-row align-items-center mt-3">
						<div class="col-4 text-left d-flex"><span class="h5 mb-0">{{$t('상위 설비 기기')}}</span></div>
						<div class="col-8">
							<b-form-select :ref="'upperTypeCode'" v-model="model.upperEquipIdx" :options="upperTypeCodeList" @input="upperDataInput"> </b-form-select>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-else>
			<div class="row-vh d-flex flex-row">
				<div class="w-50">
					<div class="d-flex flex-row align-items-center mt-3">
						<div class="col-4 text-left d-flex">
							<span class="h5 mb-0">{{$t('설비 그룹')}}</span>
						</div>
						<div class="col-8">
							<b-row>
								<b-col cols="10">
									<b-form-select :ref="'upperTypeCode'" v-model="model.equipGrpIdx" :options="groupTypeList" @input="upperDataInput"> </b-form-select>
								</b-col>
								<b-col cols="2" :style="{ padding: 0 }">
									<b-button v-b-toggle.collapse-1>{{$t('신규')}}</b-button>
								</b-col>
							</b-row>
						</div>
					</div>
					<div class="d-flex flex-row align-items-center" :style="{ justifyContent: 'right' }">
						<b-collapse id="collapse-1" class="mt-2 col-8">
							<b-card>
								<div class="d-flex flex-row align-items-center">
									<div class="col-4 text-left d-flex"><span class="h6 mb-0" :style="{ fontSize: '13px' }">{{$t('설비 그룹명')}}</span></div>
									<div class="col-8">
										<input :ref="'equipGroupName'" class="form-control" v-model="newGroup.equipGrpName" />
									</div>
								</div>
								<div class="d-flex flex-row align-items-center mt-3">
									<div class="col-4 text-left d-flex"><span class="h6 mb-0" :style="{ fontSize: '13px' }">{{$t('구역')}}</span></div>
									<div class="col-8">
										<tree-select
											:load-options="loadOptions"
											v-model="newGroup.zoneIdx"
											:placeholder="$t(controlMessages.ZONE_TREE_PLACEHOLDER_MESSAGE)"
											:noChildrenText="$t(controlMessages.ZONE_TREE_NOCHILDREN_MESSAGE)"
											:noResultsText="$t(controlMessages.COMMON_TREE_NOSEARCH_MESSAGE)"
											:multiple="false"
											:options="detailZoneList"
										/>
									</div>
								</div>
								<div class="d-flex flex-row align-items-center mt-3" :style="{ justifyContent: 'center' }">
									<b-button @click="makeNewGroup">{{$t('저장')}}</b-button>
								</div>
							</b-card>
						</b-collapse>
					</div>
				</div>
			</div>
		</div>
		<div>
			<div class="row-vh d-flex flex-row">
				<div class="w-50">
					<div class="d-flex flex-row align-items-center mt-3">
						<div class="col-4 text-left d-flex"><span class="h5 mb-0">{{$t('구역')}}</span></div>
						<div class="col-8">
							<tree-select
								:load-options="loadOptions"
								v-model="model.zoneIdx"
								:placeholder="$t(controlMessages.ZONE_TREE_PLACEHOLDER_MESSAGE)"
								:noChildrenText="$t(controlMessages.ZONE_TREE_NOCHILDREN_MESSAGE)"
								:noResultsText="$t(controlMessages.COMMON_TREE_NOSEARCH_MESSAGE)"
								:multiple="false"
								:options="detailZoneList"
							/>
						</div>
					</div>
					<div class="d-flex flex-row align-items-center mt-3">
						<div class="col-4 text-left d-flex"><span class="h5 mb-0">X {{$t('좌표')}}</span></div>
						<div class="col-8">
							<input type="number" :ref="'equipLctnX'" class="form-control" v-model="model.equipLctn.split(',')[0]" @change="coorChangeX" />
						</div>
					</div>
				</div>
				<div class="w-50" :style="{ alignSelf: 'end' }">
					<!-- <div class="d-flex flex-row align-items-center mt-3">
            <div class="col-4 text-left d-flex"><span class="h5 mb-0">사용 에너지원</span></div>
            <div class="col-8">
              <b-form-select :ref="'upperTypeCode'" v-model="isSelectedEnergy" :options="detailUpperEnergyList"> </b-form-select>
            </div>
          </div> -->
					<div class="d-flex flex-row align-items-center mt-3">
						<div class="col-4 text-left d-flex"><span class="h5 mb-0">Y {{$t('좌표')}}</span></div>
						<div class="col-8">
							<input type="number" :ref="'equipLctnY'" class="form-control" v-model="model.equipLctn.split(',')[1]" @change="coorChangeY" />
						</div>
					</div>
				</div>
			</div>
			<div class="row-vh d-flex flex-row">
				<div class="w-100">
					<div class="d-flex flex-row align-items-center mt-3">
						<div class="col-2 text-left d-flex"><span class="h5 mb-0">{{$t('기타 항목')}}</span></div>
						<div class="col-10">
							<textarea :ref="'equipProp'" class="form-control" v-model="model.equipProp" />
						</div>
					</div>
				</div>
			</div>
			<div class="row-vh d-flex flex-row">
				<div class="w-50">
					<div class="d-flex flex-row align-items-center mt-3">
						<div class="col-4 text-left d-flex"><span class="h5 mb-0">{{$t('사용여부')}}</span><i class="material-icons ml-1 m-t-1" :style="{ fontSize: '15px' }">check_circle</i></div>
						<div class="col-8">
							<select class="form-control" ref="enabled" v-model="model.enabled">
								<option value="Y">{{$t('사용')}}</option>
								<option value="N">{{$t('사용안함')}}</option>
							</select>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- </div> -->

		<new-btn @insert="onRegist" @reset="onReset" />
	</div>
</template>

<script>
	import newBtn from "./EquipDeviceNewBtn.vue";
	import backEndApi from "../../../api/backEndApi";
	import config from "@libs/config.js";
	import AppErrorHandler from "@src/appErrorHandler";
	import * as popupMessages from "@src/consts/popupMessageConsts";
	import * as controlMessages from "@src/consts/controlMessageConsts";

	export default {
		name: "EquipDeviceNewModal",
		components: {
			newBtn,
		},
		data() {
			return {
				collapseOpen: false,
				model: {
					equipTypeCode: "",
					systemCode: "",
					equipName: "",
					equipUsageCode: "",
					equipGrpIdx: null,
					upperEquipIdx: null,
					equipProp: null,
					zoneIdx: null,
					equipLctn: ",",
					enabled: null,
				},
				upperTypeData: {
					equipIdx: null,
					equipTypeCode: null,
					systemCode: null,
					equipName: null,
					equipUsageCode: null,
					equipProp: null,
					zoneIdx: null,
					equipLctn: ",",
					enabled: null,
				},
				groupTypeData: {
					equipGrpIdx: "",
					equipTypeCode: "",
					equipGrpName: "",
					enabled: "",
				},
				newGroup: {
					equipTypeCode: "",
					equipGrpName: "",
					systemCode: "",
					enabled: "Y",
					zoneIdx: 1,
				},
				isSelectedEnergy: "",
				upperTypeCodeList: null,
				groupTypeList: null,

				isUpperTypeCode: null,
				detailEquipTypeList: null,
				detailSystemInfoList: null,
				detailItemUseageList: null,
				detailUpperEnergyList: null,
				detailZoneList: null,

				xemsHost: config.backend.apiUrl,
				popupMessages,
				controlMessages,
			};
		},
		async created() {
			this.detailEquipTypeList = await this.getEquipTypeList();
			this.detailSystemInfoList = await this.getSystemInfoList();
			this.detailItemUseageList = await this.getOprtnSmallCodeList("itemUseageCode");
			this.detailUpperEnergyList = await this.getOprtnSmallCodeList("energySourceCode");
			this.detailZoneList = await this.getZoneListHierarchi();
		},
		methods: {
			async makeNewGroup() {
				if (this.model.equipTypeCode.trim() == "") {
					this.alertNoti(popupMessages.EQUIP_INSERT_DEVICE_TYPE_VALID_POPUP_MESSAGE);
					return;
				} else if (this.model.systemCode.trim() == "") {
					this.alertNoti(popupMessages.EQUIP_DEVICE_SYSTEM_CODE_INPUT_VALID_POPUP_MESSAGE);
					return;
				} else if (this.newGroup.equipGrpName.trim() == "") {
					this.alertNoti(popupMessages.EQUIP_DEVICE_GRUOP_VALID_POPUP_MESSAGE);
					return;
				}

				this.newGroup.equipTypeCode = this.model.equipTypeCode;
				this.newGroup.systemCode = this.model.systemCode;

				// 이전 리비전과 소스 에서 아래와 같이 변경함.
				/*           
        ↓↓↓↓↓
      */
				try {
					// 세이브 시작
					await backEndApi.equipInfo.insertEquipGroup(this.newGroup);
					this.alertNoti(popupMessages.COMMON_SAVE_POPUP_MESSAGE);

					this.groupTypeList = await this.getEquipGroupList(this.newGroup.equipTypeCode);
				} catch (err) {
					new AppErrorHandler(err).setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger).errHandling();
				}
			},
			coorChangeX(e) {
				this.model.equipLctn = e.target.value + "," + this.model.equipLctn.split(",")[1];
			},
			coorChangeY(e) {
				this.model.equipLctn = this.model.equipLctn.split(",")[0] + "," + e.target.value;
			},

			async getEquipTypeList() {
				// 이전 리비전과 소스 에서 아래와 같이 변경함.
				/*           
        ↓↓↓↓↓
      */
				try {
					let result = await backEndApi.equipType.getEquipTypeList();
					let options = [];
					for (let i = 0; i < result.data.length; i++) {
						options.push({
							value: result.data[i].equipTypeCode,
							text: result.data[i].equipTypeCodeName,
							upperTypeCode: result.data[i].upperTypeCode,
						});
					}

					return options;
				} catch (err) {
					new AppErrorHandler(err).setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger).errHandling();
				}
			},
			async getSystemInfoList() {
				// 이전 리비전과 소스 에서 아래와 같이 변경함.
				/*           
        ↓↓↓↓↓
      */
				try {
					let result = await backEndApi.systeminfo.getSystemInfoList();
					let options = [];
					for (let i = 0; i < result.data.length; i++) {
						options.push({
							value: result.data[i].systemCode,
							text: result.data[i].systemName,
						});
					}
					return options;
				} catch (err) {
					new AppErrorHandler(err).setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger).errHandling();
				}
			},
			async getOprtnSmallCodeList(code) {
				// 이전 리비전과 소스 에서 아래와 같이 변경함.
				/*           
        ↓↓↓↓↓
      */
				try {
					let result = await backEndApi.publicCode.getOprtnSmallCodeList(code);
					let options = [];
					for (let i = 0; i < result.data.length; i++) {
						options.push({
							value: result.data[i].smallCode,
							text: result.data[i].smallCodeName,
						});
					}
					return options;
				} catch (err) {
					new AppErrorHandler(err).setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger).errHandling();
				}
			},
			async getZoneListHierarchi() {
				// 이전 리비전과 소스 에서 아래와 같이 변경함.
				/*           
        ↓↓↓↓↓
      */
				try {
					let result = await backEndApi.zoneInfo.getZoneListHierarchi();
					let options = [];
					let temp = [];
					temp.push(result.data);
					options = this.setZoneTreeOption(temp);
					return options;
				} catch (err) {
					new AppErrorHandler(err).setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger).errHandling();
				}
			},
			setZoneTreeOption(resultData) {
				resultData.forEach((data) => {
					data.id = data.zoneIdx;
					data.label = data.zoneName;

					if (data.children.length === 0) {
						delete data.children;
					}

					if (data["children"]) {
						return this.setZoneTreeOption(data["children"]);
					} else {
						return data;
					}
				});

				return resultData;
			},
			async upperTypeCheck(value) {
				this.isUpperTypeCode = this.detailEquipTypeList.find((el) => el.value === value).upperTypeCode;

				if (this.isUpperTypeCode !== "") {
					this.upperTypeCodeList = await this.getUpperEquipList();
				} else {
					this.groupTypeList = await this.getEquipGroupList(this.model.equipTypeCode);
				}
			},
			async getUpperEquipList() {
				// 이전 리비전과 소스 에서 아래와 같이 변경함.
				/*           
        ↓↓↓↓↓
      */
				try {
					let result = await backEndApi.equipInfo.getUpperEquipList(this.isUpperTypeCode);
					let options = [];
					for (let i = 0; i < result.data.length; i++) {
						options.push({
							value: result.data[i].equipIdx,
							text: result.data[i].equipName,
						});
					}

					return options;
				} catch (err) {
					new AppErrorHandler(err).setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger).errHandling();
				}
			},
			async getEquipGroupList(equipTypeCode) {
				// 이전 리비전과 소스 에서 아래와 같이 변경함.
				/*           
        ↓↓↓↓↓
      */

				let result = await backEndApi.equipInfo.getEquipGroupList(equipTypeCode);
				let options = [];

				for (let i = 0; i < result.data.length; i++) {
					options.push({
						value: result.data[i].equipGrpIdx,
						text: result.data[i].equipGrpName,
						equipTypeCode: result.data[i].equipTypeCode,
						enabled: result.data[i].enabled,
					});
				}

				return options;
			},
			async upperDataInput(idx) {
				this.upperTypeData = await this.setUpperTypeData(idx);
			},
			async setUpperTypeData(idx) {
				// 이전 리비전과 소스 에서 아래와 같이 변경함.
				/*           
        ↓↓↓↓↓
      */
				try {
					let result = await backEndApi.equipInfo.getEquipDetailInfo(idx);
					let data = {
						equipIdx: result.data.equipIdx,
						equipTypeCode: result.data.equipTypeCode,
						systemCode: result.data.systemCode,
						equipName: result.data.equipName,
						equipUsageCode: result.data.equipUsageCode,
						equipProp: result.data.equipProp,
						zoneIdx: result.data.zoneIdx,
						equipLctn: result.data.equipLctn === "" ? "," : result.data.equipLctn,
						enabled: result.data.enabled,
					};
					return data;
				} catch (err) {
					new AppErrorHandler(err).setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger).errHandling();
				}
			},
			async onRegist() {
				console.log("New modal : onRegist");

				if (this.isValidation()) {
					console.log("is new regist");
					let param = this.model;

					// 이전 리비전과 소스 에서 아래와 같이 변경함.
					/*           
          ↓↓↓↓↓
        */

					try {
						await backEndApi.equipInfo.insertEquipInfo(param);
						this.alertNoti(popupMessages.COMMON_SAVE_POPUP_MESSAGE);
						this.$emit("hide");
						this.$emit("reSearch");
					} catch (err) {
						new AppErrorHandler(err).setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger).errHandling();
					}
				}
			},
			isValidation() {
				var flag = true;
				if (this.model.equipTypeCode.trim() === "" || this.model.equipTypeCode === null) {
					this.$refs.equipTypeCode.focus();
					this.alertNoti(popupMessages.EQUIP_DEVICE_TYPE_VALID_POPUP_MESSAGE);
					flag = false;
				} else if (this.model.equipName.trim() === "" || this.model.equipName === null) {
					this.$refs.equipName.focus();
					this.alertNoti(popupMessages.EQUIP_DEVICE_NAME_INPUT_VALID_POPUP_MESSAGE);
					flag = false;
				} else if (this.model.systemCode.trim() === "" || this.model.systemCode === null) {
					this.$refs.systemCode.focus();
					this.alertNoti(popupMessages.EQUIP_DEVICE_SYSTEM_CODE_VALID_POPUP_MESSAGE);
					flag = false;
				} else if (this.model.equipUsageCode.trim() === "" || this.model.equipUsageCode === null) {
					this.$refs.equipUsageCode.focus();
					this.alertNoti(popupMessages.EQUIP_DEVICE_USAGE_CODE_VALID_POPUP_MESSAGE);
					flag = false;
				} else if (this.model.enabled === "" || this.model.enabled === null) {
					this.$refs.enabled.focus();
					this.alertNoti(popupMessages.COMMON_USAGE_VALID_POPUP_MESSAGE);
					flag = false;
				}

				return flag;
			},
			onReset() {
				this.model = {
					equipTypeCode: "",
					systemCode: "",
					equipName: "",
					equipUsageCode: "",
					equipGrpIdx: "",
					upperEquipIdx: "",
					equipProp: "",
					zoneIdx: "",
					equipLctn: "",
					enabled: "",
				};
			},
			loadOptions({ callback }) {
				callback();
			},
		},
	};
</script>

<style lang="scss"></style>
