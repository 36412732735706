<template>
  <div class="col-xl-12">
    <div class="row">
      <div class="col-lg-12">
        <div class="pull-left">
          <h3>
            {{$t('운영 기준정보 관리')}} > {{$t('설비 관리')}} > {{$t('설비 기기')}}
          </h3>
        </div>
      </div>
    </div>

    <hr class="mt-3 mb-3" />

    <div class="row">
      <search-box :ref="'search'" @search:data="onSearch" @new="newModal" />
    </div>

    <b-row :style="{ display: 'contents' }">
      <div class="bg-white col-9" :style="{ padding: 0, minWidth: '900px' }">
        <grid-box :data="items" @reSearch="reSearch" />
      </div>
    </b-row>

    <b-modal ref="newModal" size="xl" :hide-footer="true" :title="$t('신규 설비 기기')">
      <new-modal @reSearch="reSearch" @hide="hide" />
    </b-modal>
  </div>
</template>

<script>
// NOTE - 설비 관리 > 설비 기기

// import backEndApi from "../../../../api/backEndApi";
import searchBox from "./EquipDeviceSearch.vue";
import gridBox from "./EquipDeviceTable.vue";
import newModal from "./EquipDeviceNewModal.vue";

export default {
  name: "EquipDevice",
  props: [],
  components: {
    searchBox,
    gridBox,
    newModal,
  },
  watch: {},
  data() {
    return {
      items: null,
    };
  },
  created() {},
  mounted() {},
  methods: {
    onSearch(data) {
      this.items = data;
    },
    newModal() {
      this.$refs.newModal.show();
    },
    hide() {
      this.$refs.newModal.hide();
    },
    reSearch() {
      this.$refs.search.reSearch();
    },
  },
};
</script>

<style scoped></style>
